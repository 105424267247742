@font-face {
  font-family: 'Ringside Regular SSm';
  src: url('https://media.inquirer.com/fonts/RingsideRegularSSm-Book_Web.woff2');
  src: url('https://media.inquirer.com/fonts/RingsideRegularSSm-Book_Web.woff2')
      format('woff2'),
    url('https://media.inquirer.com/fonts/RingsideRegularSSm-Book_Web.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ringside Regular SSm';
  src: url('https://media.inquirer.com/fonts/RingsideRegularSSm-BookItalic_Web.woff2');
  src: url('https://media.inquirer.com/fonts/RingsideRegularSSm-BookItalic_Web.woff2')
      format('woff2'),
    url('https://media.inquirer.com/fonts/RingsideRegularSSm-BookItalic_Web.woff')
      format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Ringside Regular SSm';
  src: url('https://media.inquirer.com/fonts/RingsideRegularSSm-Bold_Web.woff2');
  src: url('https://media.inquirer.com/fonts/RingsideRegularSSm-Bold_Web.woff2')
      format('woff2'),
    url('https://media.inquirer.com/fonts/RingsideRegularSSm-Bold_Web.woff')
      format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ringside Regular SSm';
  src: url('https://media.inquirer.com/fonts/RingsideRegularSSm-BoldItalic_Web.woff2');
  src: url('https://media.inquirer.com/fonts/RingsideRegularSSm-BoldItalic_Web.woff2')
      format('woff2'),
    url('https://media.inquirer.com/fonts/RingsideRegularSSm-BoldItalic_Web.woff')
      format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
