.Curation-container {
  padding-top: 10px;
  display: flex
}

.Curation-container .pull-right{
  display: flex;
  justify-content: flex-end;
}

.Curation-container .new-item-btn a{
  font-size: 100px;
}

.Curation-container .new-item-btn a:hover{
  text-decoration: none;
}

.Curation-container .action {
  color: #d1d4dc;
  font-size: 18px;
  padding: 10px;
}

.Curation-container .action:hover{
  color: #6c757d;
  cursor: pointer;
}

.Curation-container .action-bar {
  margin-bottom: 15px;
}
