.Notif-container {
  padding-left: 48px;
  padding-right: 48px;
  display: flex;
}

.Notif-container .pull-right{
  display: flex;
  justify-content: flex-end;
  padding-right: 0px;
}

.Notif-container .Notif-compose-btn {
  margin-top: 40px;
  margin-bottom: 20px;
}

.Notif-container .Notif-reports-btn {
  margin-top: 55px;
  float: right;
  text-decoration: underline;
}

.Notif-container .Notif-reports-icon {
  width: 30px;
  padding-bottom: 2px;
  filter: invert(28%) sepia(81%) saturate(1219%) hue-rotate(195deg) brightness(90%) contrast(86%);
}

.Notif-container .error-log {
  padding-bottom: 20px;
}

.Notif-container table th {
  font-weight: normal;
  color: #6c757d;
  font-size: 0.8em;
}

.Notif-container table tr.collapse-tr-hide {
  display: none
}
.Notif-container table tr.collapse-tr-show td{
  border-top: 0px;
}

.Notif-container .collapse-action {
  color: #d1d4dc;
  font-size: 20px;
}

.Notif-container .collapse-action:hover{
  color: #6c757d;
  cursor: pointer;
}

.Notif-list {
  background: white;
}

.Notif-list .header {
  padding: 16px 16px 16px 0;
  font-weight: bold;
}

.Notif-list td .title {
  width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.Notif-list th {
  text-transform: uppercase;
}
.Notif-list th.status {
  width: 112px;
}
.Notif-list td.no-items {
  text-align: center;
  color: #d1d4dc;
  font-size: 1.5em;
  font-weight: bold;
}
.Notif-list td .msg-body {
  overflow-wrap: break-word;
  margin-bottom: 20px;
}

.msg-body-error h6 {
  max-width: 400px;
}
.msg-success {
  color: #28a745;
}
.msg-error {
  color: #dc3545;
}
.word-count {
  text-align: right;
  font-size: 8px;
  color: #d1d4dc;
}

.device-tokens-box {
  display: flex;
  border: 1px solid #d8dce6;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding-top: 3px;
  padding-bottom: 3px;
}
.fill-remaining-space {
  flex: 1 1 auto;
}
.tokens-box {
  width: 325px;
  display: flex;
  flex-flow: row wrap;
}

.add-more-token-button {
  border-radius: 50%;
  margin: auto;
  margin-right: 5px;
  height: 16px !important;
  width: 16px;
  padding: 0px;
  color: #3e3f42;
  background-color: transparent;
  background-image: none;
  border-color: #3e3f42;
}
.add-more-token-button:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}
.add-more-token-button:focus {
 box-shadow: none !important
}

.md-chip {
  display: flex;
  background: #4ccbab;
  padding: 0px 10px 0px 10px;
  border-radius: 4px;
  color: #ffffff;
  margin: 5px 0px 0px 5px;
  width: fit-content;
  height: auto;
  max-width: 95%;
  margin-bottom: 5px;
}

.md-chip span {
  font-size: 15px;
  max-width: 90%;
  word-wrap: break-word;
}
.md-chip-remove {
  border: 0;
  padding: 0;
  cursor: pointer;
  font: inherit;
  line-height: 20px;
  background-color: transparent;
  margin-left: 15px;
  color: white
}

.border-input {
  border-radius: 0.25rem !important;
}

.margin-left-down-10 {
  margin-left: -10px;
}
.add-token-modal {
  padding-left: 50px !important;
  padding-right: 50px !important;
  width: 530px;
  margin-left: auto;
  margin-right: auto;
}

.error-duplicated {
  margin-left: 40px;
}
.limit-input-token-box {
  max-height: 350px;
  overflow: auto;
  padding-bottom: 10px;
}

.modal-dialog > .modal-content > .modal-body .target-input .chip-area .close-button {
  margin-left: 5px;
  cursor: pointer;
}

.modal-dialog > .modal-content > .modal-body .target-input .chip-area .close-button:hover {
  opacity: .75;
}

.z-index-popover {
  z-index: 1060;
}
