@import './Fonts.css';

body {
  margin: 0;
  padding: 0;
  font-family: 'Ringside Regular SSm';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.selector-card-preview-type {
  cursor: pointer;
}

.selector-card-preview-type:hover {
  opacity: 0.7;
}

.pointer {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

@keyframes swap-in-left {
  from {
    transform: translateX(110%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes swap-out-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-110%);
  }
}

@keyframes swap-in-right {
  from {
    transform: translateX(-110%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes swap-out-right {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(110%);
  }
}

.swap-in-left {
  animation: swap-in-left 0.8s forwards;
}

.swap-out-left {
  animation: swap-out-left 0.8s forwards;
}

.swap-in-right {
  animation: swap-in-right 0.8s forwards;
}

.swap-out-right {
  animation: swap-out-right 0.8s forwards;
}
