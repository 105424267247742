.SearchArticle-preview {
  margin: 0 auto;
  display: inline-flex;
}

.SearchArticle-preview .remove-btn {
  color: #d1d4dc;
  font-size: 18px;
}

.SearchArticle-preview .remove-btn:hover{
  color: #6c757d;
  cursor: pointer;
}

