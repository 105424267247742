:root {
  --gutter: 50px;
}

.Carousel {
  padding: var(--gutter) 0;
  display: grid;
  grid-gap: var(--gutter) 0;
  grid-template-columns: var(--gutter) 1fr var(--gutter);
  align-content: start;

  width: 100%;
  overflow-y: scroll;
}

.Carousel > * {
  grid-column: 2 / -2;
}

.Carousel > .full {
  grid-column: 1 / -1;
}

.Carousel .hs {
  display: grid;
  grid-gap: calc(var(--gutter) / 2);
  grid-template-columns: 10px repeat(var(--total), calc(50% - var(--gutter) * 2)) 10px;
  grid-template-rows: minmax(300px, 1fr);

  overflow-x: scroll;
  scroll-snap-type: x proximity;
  padding-bottom: calc(.75 * var(--gutter));
  margin-bottom: calc(-.25 * var(--gutter));
}

.Carousel .hs:before, .hs:after {
  content: '';
}

.Carousel .hs > li, .item {
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 8px;
}

.Carousel ul {
  list-style: none;
  padding: 0;
}

.Carousel .align-center {
  text-align: center;
}

