.targeting-title {
  color: #989898;
  font-size: 15px;
  letter-spacing: 0;
  text-align: center;
  max-width: 100%;
}

.title-preview {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #979797;
  line-height: 0.1em;
  margin-top: 15px;
}

.title-preview span {
  background: #ffffff;
  color:  #989898;
  font-size: 14px;
  font-weight: bold;
  height: 16px;
  letter-spacing: 0;
  text-transform: uppercase;
  margin-right: 3px;
}

.normal-notification-preview {
  font-size: 16px;
  text-align: left;
  width: 325px;
  margin: auto;
  padding-top: 15px;
}

.normal-notification-preview p {
  margin-bottom: 0;
}

.normal-notification-preview img {
  max-width: 325px;
  margin-bottom: 15px;
}

.margin-bottom {
  margin-bottom: 20px;
}

.small-text {
  margin-top: 5px;
  font-size: 13px;
}

.image-box {
  width: 300px;
  height: 150px;
  margin: 15px auto auto auto;
}
