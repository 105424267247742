.ModeBar {
}

.ModeBar .toggleSwitch {
  vertical-align: middle;
  margin: 0 10px;
}

.ModeBar span {
  vertical-align: middle;
  margin: 0 5px;
}
